<template>
  <div class="success">
    <img src="http://cdn.9kd.com/kdnet/turnableGift.png" alt="" />
    <div class="div1">领取成功</div>
    <div class="div2">赣府酒家提供的满200元减100元优惠券已发放到你的账户</div>
    <div class="div3">快去下载凯迪网APP查看优惠券消息</div>
    <div class="button_group">
      <van-button class="btn btn1" @click="openApp(CONFIG.scheme)" id="downloadBtn">去凯迪APP</van-button>
      <van-button class="btn btn2" @click="handleShare" id="shareBtn">分享活动</van-button>
    </div>
    <div id="wx-mark" v-if="wxMarkShow" @click="wxClose">
      <div class="mark" id="mark">
        <div class="yd_tip">
          <p class="yd_more">
            1 点击右上角
            <img src="http://cdn.9kd.com/kdnet/activicty_20210122/yd_more.png" alt="" />
          </p>
          <p>2 选择在浏览器中打开</p>
        </div>
        <div class="yd_img">
          <img src="http://cdn.9kd.com/kdnet/activicty_20210122/jt_yd.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  data() {
    return {
      timer: null,
      downTimer: null,
      openTimer: null,
      CONFIG: {
        android: 'kdapp://main:7700',
        ios: 'https://www.9kd.com/',
        scheme: 'kdapp://main:7700',
      },
      wxMarkShow: false,
    }
  },
  computed: {
    shareSuccess() {
      return this.$store.state.shareSuccess
    },
  },
  created() {},
  beforeDestroy() {
    setInterval(() => {
      clearInterval(this.timer)
    }, 3000)
  },

  methods: {
    //点击分享活动
    handleShare() {
      window._hmt = window._hmt || []
      window._hmt.push(['_trackEvent', '分享活动', '点击', '领取成功页']) //赣菜分享事件跟踪
      this.$router.push('/turnable')
      //   this.$toast('请使用浏览器的分享功能，把该活动分享出去')
      const toast = Toast({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '请使用浏览器的分享功能\n把该活动分享出去',
      })

      let second = 3
      this.timer = setInterval(() => {
        second--
        if (!second) {
          clearInterval(this.timer)
          // 手动清除 Toast
          Toast.clear()
        }
      }, 1000)
    },
    //下载app
    openApp(url) {
      window._hmt = window._hmt || []
      window._hmt.push(['_trackEvent', '去凯迪APP', '点击', '领取成功页']) //赣菜下载事件跟踪
      let { isAndroid, isIOS, isIOS9 } = this.judgePhoneType()
      if (this.isWeiXin()) {
        this.footerShow = false
        this.wxMarkShow = true
        return
      }
      this.clearTimer()
      if (isAndroid) {
        if (this.isQQBrowser()) {
          this.$toast('请您在其他浏览器中打开')
          return
        }
        let hasApp = true,
          t = 1000,
          t1 = Date.now(),
          ifr = document.createElement('iframe')
        this.downTimer = setTimeout(function () {
          // 未安装
          if (!hasApp) {
            // 安卓没有安装直接跳转到下载页面
            window.location.href = 'https://www.9kd.com/kd-net/kd_download/kd_download.html'
          }
          document.body.removeChild(ifr)
        }, 2000)
        ifr.setAttribute('src', url)
        ifr.setAttribute('style', 'display:none')
        document.body.appendChild(ifr)

        this.openTimer = setTimeout(function () {
          //启动app时间较长处理
          let t2 = Date.now()
          if (t2 - t1 < t + 100) {
            hasApp = false
          }
        }, t)
      }
      if (isIOS9 || isIOS) {
        window.location = 'https://apps.apple.com/cn/app/%E5%87%AF%E8%BF%AA%E6%96%B0%E9%97%BB-%E6%9C%89%E4%BD%A0-%E6%9B%B4%E6%9C%89%E5%BD%B1%E5%93%8D%E5%8A%9B/id1533642383'
      }
    },
    //判断手机类型
    judgePhoneType() {
      let isAndroid = false,
        isIOS = false,
        isIOS9 = false,
        version,
        u = navigator.userAgent,
        ua = u.toLowerCase()
      //Android系统
      if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
        //android终端或者uc浏览器
        isAndroid = true
      }
      //ios
      if (ua.indexOf('like mac os x') > 0) {
        let regStr_saf = /os [\d._]*/gi
        let verinfo = ua.match(regStr_saf)
        version = (verinfo + '').replace(/[^0-9|_.]/gi, '').replace(/_/gi, '.')
      }
      let version_str = version + ''
      // ios9以上
      if (version_str !== 'undefined' && version_str.length > 0) {
        version = parseInt(version)
        if (version >= 8) {
          isIOS9 = true
        } else {
          isIOS = true
        }
      }
      return { isAndroid, isIOS, isIOS9 }
    },
    //判断是否是微信浏览器
    isWeiXin() {
      return /micromessenger/i.test(navigator.userAgent.toLowerCase()) || typeof navigator.wxuserAgent !== 'undefined'
    },
    //微信遮罩关闭
    wxClose() {
      this.wxMarkShow = false
    },
    //QQ浏览器
    isQQBrowser() {
      return /mqqbrowser/i.test(navigator.userAgent.toLowerCase())
    },
    //清除定时器
    clearTimer() {
      if (this.downTimer) {
        clearTimeout(this.downTimer)
      }
      if (this.openTimer) {
        clearTimeout(this.openTimer)
      }
    },
  },
}
</script>

<style scoped lang="less">
.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 70%;
  img {
    width: 217px;
    height: 168px;
    margin-top: 45px;
  }
  .div1 {
    width: 82px;
    height: 28px;
    opacity: 1;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    color: #f7321c;
    margin: 17px 0;
  }
  .div2 {
    width: 283px;
    height: 43px;
    opacity: 1;
    font-size: 15px;
    text-align: center;
    color: #333333;
  }
  .div3 {
    width: 214px;
    height: 18px;
    opacity: 1;
    font-size: 13px;
    text-align: center;
    color: #c8cbd2;
    margin: 8px 0 39px 0;
  }
  .btn {
    width: 134px;
    height: 40px;
    font-size: 13px;
    text-align: center;
    line-height: 40px;
    border: none !important;
  }
  .btn1 {
    background: url('http://cdn.9kd.com/kdnet/turnableQ.png') no-repeat;
    background-size: 100%;
    color: #f7321c;
    margin-right: 8px;
  }
  .btn2 {
    background: url('http://cdn.9kd.com/kdnet/turnableQ2%402x.png') no-repeat;
    background-size: 100%;
    color: #ffffff;
  }
  #wx-mark {
    .mark {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.75);
      display: flex;
      justify-content: flex-end;
    }
    .yd_tip {
      margin: 10px 20px 0 0;
      color: white;
      font-size: 14px;
      line-height: 24px;
    }
    .yd_tip .yd_more {
      display: flex;
      align-items: baseline;
    }
    .yd_tip .yd_more img {
      width: auto;
      height: 3px;
      margin-left: 2px;
    }
    .yd_img {
      margin: 10px 20px 0 0;
      width: 50px;
      height: 30px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
